import React, { useEffect } from "react";

const config = {
  autoplay: true,
  controls: "system",
  systemFullscreen: true,
  live: true
};

const Live = () => {
  const { innerWidth, innerHeight, navigator } = window;
  const [width, height] =
    innerWidth / 16 < innerHeight / 9
      ? [innerWidth, (innerWidth / 16) * 9]
      : [(innerHeight / 9) * 16, innerHeight];

  const onMobile = navigator.userAgent.toLowerCase().indexOf("mobile") > 0;

  useEffect(() => {
    if (onMobile) {
      // @ts-ignore
      new TcPlayer("player", {
        m3u8: "https://liveplay.veritaschina.com/live/livestream.m3u8",
        width: width,
        height: height,
        ...config
      });
    } else {
      // @ts-ignore
      new TcPlayer("player", {
        flv: "https://liveplay.veritaschina.com/live/livestream.flv",
        h5_flv: true,
        width: width,
        height: height,
        ...config
      });
    }
  }, [onMobile, width, height]);

  return (
    <div
      id="player"
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    />
  );
};

export default Live;
